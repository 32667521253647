<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

      <base-material-tabs
          v-model="active_tab"
          color="warning"
          icons-and-text
        >
      <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
          >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>

          <v-tabs-items
            v-model="active_tab"
            class="pt-5 transparent"
          >
            <v-tab-item>
              <base-material-card
                icon="mdi-email-send-outline"
                title="Arsip Surat Keluar"
                class="px-5 py-3"
                >
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="listQuery.search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col>
                    <v-btn color="blue" @click="getListAllOpened()">Search</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      &nbsp;
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-data-table
                      :headers="headers"
                      :items="listDataArsip"
                      :items-per-page="15"
                      class="elevation-1"
                      :server-items-length="totalData"
                      :loading="listLoading"
                      :options.sync="tableOpt"
                    >
                    <template v-slot:item.subject="{ item }">
                      <span v-if="item.eostatus_to == 25"><v-icon>mdi-clipboard-text</v-icon></span><!-- DISPOSISI-->
                      <span v-else-if="item.eostatus_to == 26"><v-icon>mdi-mail</v-icon></span><!-- TTD-->
                      <span v-else-if="item.eostatus_to == 27"><v-icon>mdi-share</v-icon></span><!-- SHARE-->
                      <span v-else-if="item.eostatus_to == 28"><v-icon>mdi-forum</v-icon></span><!-- KOMENTAR-->
                      <span v-else><v-icon>mdi-information</v-icon></span><!-- LAINNYA-->
                      &nbsp;<span>{{ item.noSurat }}</span> | <span>{{ item.perihalSurat }}</span><br>
                      <span style="font-size:10px;font-weight:500;">&nbsp;=>&nbsp;<i>{{ item.subject }}</i></span>
                      
                    </template>
                  
                    <template v-slot:item.topos="{ item }">
                      <span style="font-size:11px;"><b>{{ item.to }}</b><br>({{ item.topos}})</span>
                    </template>
                    <template v-slot:item.log_from="{ item }">
                      <span style="font-size:12px;" v-html="item.log_from "></span>
                    </template>

                    <template v-slot:item.aksi="{ item }">

                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary " v-on="on" fab x-small @click="viewProcess(item.id, item.id_letter, item.log_from)" >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Buka Surat</span>
                      </v-tooltip>
                      
                    </template>

                  </v-data-table>
                  
              </base-material-card>
            </v-tab-item>
            </v-tabs-items>
        
        </base-material-tabs>        
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-left"
        cols="6"
        md="6">
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
        <div class="display-1">LEGEND</div>
        <hr>
           <table>
          <tr>
            <td><span><v-icon>mdi-clipboard-text</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>DISPOSISI SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-mail</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>TTD/ PERSETUJUAN SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-share</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>SHARE SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-forum</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>KOMENTAR SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-information</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>LAINNYA</span></td>
          </tr>
        </table>
        </v-alert>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment'
  import secureStorage from '@/utils/secureStorage'
  import { getSKAllOpened, countSKAllOpened, updateLetterInOut} from '@/api/letterinout'
  import { getletterGUID } from '@/api/datasurat'
  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'SuratKeluar',

    data: () => ({
      active_tab: 1,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'ARSIP Surat Keluar',
          icon: 'mdi-send',
        }
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
          width:'50',
        },
        { text: 'Perihal', value: 'subject', align: 'left', class:'subtitle-1' },
        { text: 'Kepada', value: 'topos', width:'20%', align: 'center', class:'subtitle-1' },
        { text: 'Tanggal', value: 'log_from',  width:'15%',  align: 'center', class:'subtitle-1'},
        { text: 'Aksi', value: 'aksi', width:'150', align: 'center', class:'subtitle-1'}
      ],
      isLoading: false,
      listDataArsip: [],
      currRole: null,
      totalData: 0,
      listLoading: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: ''
      }

    }),
    watch: {
      tableOpt: {
        handler () {
          this.getListAllOpened()
        },
        deep: true,
      },
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
    },
    destroyed () {
    },
    mounted () {
      const vm = this
      const idtab = this.$route.params && this.$route.params.idtab
      vm.active_tab = parseInt(idtab)
      vm.currRole = secureStorage.getItem('currRole')
    },
    methods: {
      goFileLink (uri) {
        var open = window.open(uri,'_blank')
          if (!open || open == null || typeof(open)=='undefined'){
            var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri 
            console.log(errmsg)
            vm.$alert(errmsg)
          }
      },
      viewProcess(idSM, idLetter, logfrom){
        const vm = this
        vm.isLoading = true
        
        // console.log('viewProcess', idLetter)
        getletterGUID(idLetter).then(response => {
          var data = response.data
          // console.log(data)
          if(data){
            var uri = _GLOBAL.PORTALURL + '/#/surat/v/' + data[0].letterGUID
            var open = window.open(uri,'_blank')
            if (!open || open == null || typeof(open)=='undefined'){
              var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri 
              console.log(errmsg)
              vm.$alert(errmsg)
            }
          }
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
        
        //Update log
        var m = moment()
        var str = ''
        if (logfrom == null) {
          str = m.format('DD/MM/YYYY HH:mm:ss') + ' - Open'
        } else {
          str = logfrom + '<br>' + m.format('DD/MM/YYYY HH:mm:ss') + ' - Open'
        }

        var updData = { log_from: str }
        updateLetterInOut(updData, idSM).then(response => {
          // console.log(response.data)
        })

      },
      
      getListAllOpened () {
        const vm = this
        if(vm.currRole == null) return false
        vm.listDataArsip = []

        const { sortBy, sortDesc, page, itemsPerPage } = vm.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        if(!itemsPerPage) {
          vm.listQuery.page = 1
          vm.listQuery.limit = 15
        }else {
          // console.log('itemsPerPage: ',itemsPerPage)
          vm.listQuery.limit = itemsPerPage == -1 ? vm.totalData : itemsPerPage
        }
        // console.log('listQuery: ',vm.listQuery)
        // get total data
        vm.listLoading = true
        countSKAllOpened(vm.listQuery, vm.currRole.id).then(response => {
          var data = response.data

          vm.totalData = parseInt(data)
          // console.log(vm.totalData)
          vm.listLoading = false
        })
        // get list of data
        vm.listLoading = true
        getSKAllOpened(vm.listQuery, vm.currRole.id).then(response => {
          // console.log(response.data)
          var array = response.data
          var users = secureStorage.getItem('userall')
          var posall = secureStorage.getItem('posall')
          array.forEach(element => {
            // console.log(element)

            var to = users.find(x => x.id === element.hruserpositionsfrom_user)
            var topos = posall.find(x => x.id === element.hrpositions_to)
            if(to && topos){
              element['to'] = to.fullname
              element['topos'] = topos.pos_name
            }
            
            element['jenis'] = '-'
            if (element.status_name){
              element['jenis'] = element.status_name
            }
          })
          if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
            array = array.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }
          vm.listDataArsip = array
          vm.listLoading = false
        })
      },
      
      delData (id) {
        const vm = this
        vm.idDel = id
        vm.delConfirm = true
      },
      doDeletedata () {
        const vm = this
        vm.delConfirm = false
        if (vm.idDel == 0) {
          return false
        }
        vm.isLoading = true
        deleteLetterInOut(vm.idDel).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false
          vm.feedingTable()
          vm.toast = {
            show: true, color:'green', text: 'Surat Masuk berhasil terhapus.', timeout: 2000
          }
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
           vm.toast = {
            show: true, color:'red', text: 'Surat Masuk GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
          }
        })
      },
    }
  }
</script>
